import { AmsOrdersClient, AmsOrderAssignmentStatus, AmsOrderStatusEnum, WarehouseLocationModel, ConfigurationViewModel, LookupAndReasonTypeModel, LookupAndReasonTypesViewModel } from '@/api/api';
import { Component, Vue, Watch } from 'vue-property-decorator';
import debounce from 'lodash.debounce';
import {
  ClerkAction,
  clerkNamespace,
  ClerkOrdersGet,
  ClerkState,
  dispatchClerkAction
} from '@/views/Clerk/_state/clerk-module-type';
import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import { ConfigurationGetter, ConfigurationNamespace } from '@/store/configuration/configuration.module.types';
import { computed } from 'vue';

@Component
export default class OrderListFilter extends Vue {
  readonly AmsOrderStatusEnum = AmsOrderStatusEnum;

  showSearch = false;
  filterButtonStyles = {
    'margin-bottom': '10px',
    'margin-left': '10px',
    'box-shadow': '0px 2px 4px #ccc'
  };
  iconStyles = {
    color: 'inherit'
  };
  headingStyles = {
    'font-size': '16px'
  };
  dialog: boolean = false;
  locationCodeItems: WarehouseLocationModel[] = [];

  orderStatusItems = [
    {
      text: 'All Orders',
      value: AmsOrderStatusEnum.All,
      icon: 'mdi-briefcase-outline'
    },
    {
      text: 'Not Started',
      value: AmsOrderStatusEnum.NotStarted,
      icon: 'mdi-briefcase-outline'
    },
    {
      text: 'In Progress',
      value: AmsOrderStatusEnum.InProgress,
      icon: 'mdi-check-circle-outline'
    },
    {
      text: 'Paused',
      value: AmsOrderStatusEnum.Paused,
      icon: 'mdi-briefcase-upload-outline'
    },
    {
      text: 'Completed',
      value: AmsOrderStatusEnum.Completed,
      icon: 'mdi-briefcase-check'
    },
    {
      text: 'Returned',
      value: AmsOrderStatusEnum.Returned,
      icon: 'mdi-briefcase-remove'
    },
    {
      text: 'Awaiting QC',
      value: AmsOrderStatusEnum.AwaitingQc,
      icon: 'mdi-briefcase-clock'
    },
    {
      text: 'QC In Progress',
      value: AmsOrderStatusEnum.QcInProgress,
      icon: 'mdi-briefcase-search'
    },
    {
      text: 'Cancelled',
      value: AmsOrderStatusEnum.Cancelled,
      icon: 'mdi-cancel'
    }
  ];

  @ConfigurationNamespace.Getter(ConfigurationGetter.configurations)
  readonly configuration!: ConfigurationViewModel;

  public returnReasonItems = computed(() => {
    return this.configuration?.lookupAndReasonTypes;
  });

  @clerkNamespace.State(ClerkState.clerkOrdersGet)
  filters!: ClerkOrdersGet;

  onSearchDebounce = debounce(this.onSearch, 250);

  /**
   * The number of filter types applied. 0 if no filters are applied.
   * Only care about number inside the modal, not the search bar.
   */
  get numberOfModalFilters(): number {
    let count = 0;
    if (this.filters.locationCodes && this.filters.locationCodes.length > 0) {
      count++;
    }
    if (this.filters.returnReasons && this.filters.returnReasons.length > 0) {
      count++;
    }

    return count;
  }

  public loadingWarehouseLocations = false;
  async mounted() {
    this.loadingWarehouseLocations = true;
    const amsOrdersClient = new AmsOrdersClient();
    try {
      const newLocationCodeItems = await amsOrdersClient.getWarehouseLocations();
      this.locationCodeItems = newLocationCodeItems;
    } catch (error) {
      dispatchAlertAction('showSnackbar', {
        message: 'Failed to load warehouse locations',
        color: 'error'
      });
    } finally {
      this.loadingWarehouseLocations = false;
    }
  }

  onClickOutside(): void {
    if (!this.filters.searchText) {
      this.showSearch = false;
    }
  }

  async onRefresh(): Promise<void> {
    await dispatchClerkAction(ClerkAction.updateClerkOrdersGet, {
      updates: {}
    });
  }

  async onSelectStatus(orderStatus: AmsOrderStatusEnum): Promise<void> {
    await dispatchClerkAction(ClerkAction.updateClerkOrdersGet, {
      updates: {
        orderStatus: orderStatus,
        page: 1
      }
    });
  }

  async onSelectLocationCode(locationCodes: string[]): Promise<void> {
    await dispatchClerkAction(ClerkAction.updateClerkOrdersGet, {
      updates: {
        locationCodes,
        page: 1
      }
    });
  }

  async onSelectReturnReasons(returnReasons: string[]): Promise<void> {
    await dispatchClerkAction(ClerkAction.updateClerkOrdersGet, {
      updates: {
        returnReasons,
        page: 1
      }
    });
  }

  async onSelectAssignmentStatus(assignmentStatus: AmsOrderAssignmentStatus): Promise<void> {
    await dispatchClerkAction(ClerkAction.updateClerkOrdersGet, {
      updates: {
        orderAssignmentStatus: assignmentStatus,
        page: 1
      }
    });
  }

  private async onSearch(text: string): Promise<void> {
    await dispatchClerkAction(ClerkAction.updateClerkOrdersGet, {
      updates: {
        searchText: text,
        page: 1
      }
    });
  }

  public async onModalResetFilters(): Promise<void> {
    this.dialog = false;

    // clean out modal filters, close the model, and refresh the list
    await dispatchClerkAction(ClerkAction.updateClerkOrdersGet, {
      updates: {
        locationCodes: [],
        returnReasons: [],
        searchText: '',
        orderStatus: undefined,
        orderAssignmentStatus: undefined,
        page: 1
      }
    });
  }
}
